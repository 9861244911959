<template>
  <div class="result-container">
    <my-header :isSenior.sync="isSenior" />
    <div class="box">
      <div class="left">
        <table cellspacing="1">
          <tbody>
            <tr>
              <td class="td1">正题名</td>
              <td class="td2">{{ paperData.title }}</td>
            </tr>
            <tr>
              <td class="td1">报纸名称汉语拼音</td>
              <td class="td2">{{ paperData.namePinYin }}</td>
            </tr>
            <tr>
              <td class="td1">广东省立中山图书馆胶卷片盘代号</td>
              <td class="td2">{{ paperData.handReelCode }}</td>
            </tr>
            <tr>
              <td class="td1">主编</td>
              <td class="td2">{{ paperData.author }}</td>
            </tr>
            <tr>
              <td class="td1">版本说明</td>
              <td class="td2">{{ paperData.edition }}</td>
            </tr>

            <tr>
              <td class="td1">出版地</td>
              <td class="td2">{{ paperData.placeOfPublication }}</td>
            </tr>
            <tr>
              <td class="td1">出版者</td>
              <td class="td2">{{ paperData.publisher }}</td>
            </tr>
            <tr>
              <td class="td1">出版时间</td>
              <td class="td2">{{ paperData.dateOfPublication }}</td>
            </tr>
            <tr>
              <td class="td1">特定文献类型标识和文献数量</td>
              <td class="td2">{{ paperData.typeAndCount }}</td>
            </tr>
            <tr>
              <td class="td1">尺寸</td>
              <td class="td2">{{ paperData.size }}</td>
            </tr>
            <tr>
              <td class="td1">一般性附注</td>
              <td class="td2">{{ paperData.notes }}</td>
            </tr>
            <tr>
              <td class="td1">出版周期</td>
              <td class="td2">{{ paperData.frequency }}</td>
            </tr>
            <tr>
              <td class="td1">团体名称</td>
              <td class="td2">{{ paperData.teamName }}</td>
            </tr>
            <tr>
              <td class="td1">责任方式</td>
              <td class="td2">{{ paperData.role }}</td>
            </tr>
            <!-- <tr>
                <td class="td1">责任者</td>
                <td class="td2">{{ paperData.contributor }}</td>
              </tr> -->

            <tr>
              <td class="td1">原文献收藏单位</td>
              <td class="td2">{{ paperData.holdings }}</td>
            </tr>

            <tr>
              <td class="td1">原文献索书号或书次、种次号</td>
              <td class="td2">{{ paperData.collectZindex }}</td>
            </tr>
            <tr>
              <td class="td1">片盘代号</td>
              <td class="td2">{{ paperData.discCode }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="right">
        <div class="time">时间分类</div>
        <table>
          <tbody>
            <tr v-for="v in paperYearList" :key="v.title">
              <td>
                <img src="../../assets/jt.png" alt="" />
                <span class="year">{{ v.title }}年</span>
              </td>
              <td>
                <ul>
                  <li
                    v-for="(v2, i2) in v.childList"
                    :key="i2"
                    @click="gotoEBook(v2.issue)"
                  >
                    {{ v2.title }}
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
    <script>
import myHeader from "../../components/header/index.vue";
import { getPaperData, getPaperYear } from "../../api/overseaschinese";
export default {
  components: {
    myHeader,
  },
  data() {
    return {
      isSenior: false,
      paperName: "",
      paperYearList: [],
      paperData: {},
    };
  },
  created() {
    this.paperName = this.$route.query.title;
    this.getPaperYear();
    this.getPaperData();
  },
  methods: {
    gotoSer() {
      this.$router.push({ name: "Senior", query: { from: "result" } });
    },
    async getPaperYear() {
      try {
        const res = await getPaperYear({ paperName: this.paperName });
        this.paperYearList = res;
      } catch (error) {}
    },
    async getPaperData() {
      try {
        const res = await getPaperData({ title: this.paperName });
        this.paperData = res;
      } catch (error) {}
    },
    gotoEBook(issue) {
      let routeData = this.$router.resolve({
        path: "/overBook",
        query: {
          qs: issue,
          paperName: this.$route.query.title,
          searchKeyWord: this.$route.query.searchKeyWord,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
    
<style lang="scss" scoped>
.result-container {
  background: #ededed;
  padding-bottom: 20px;
}
.box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 10px;
  border: 2px solid #1a416c;
  display: flex;

  .left {
    width: 50%;
    padding: 20px 10px;
    table {
      border: 1px solid;
      border-color: #ede6d6;
      width: 100%;

      tr {
        background: #fff;

        .td1 {
          line-height: 45px;
          padding-left: 5px;
          width: 236px;
          background-color: #f6f4f0;
          border-bottom: 1px solid #ede6d6;
          color: #a89a72;
          font-size: 14px;
        }
        .td2 {
          min-height: 36px;
          padding: 8px;
          font-size: 14px;
          line-height: 200%;
          border-bottom: 1px solid #ede6d6;
          color: #78756d;
        }
      }
    }
  }
  .right {
    width: 50%;
    padding: 20px 10px;
    .time {
      width: 100%;
      height: 35px;
      font-size: 14px;
      background-color: #fff;
      line-height: 35px;
      padding-left: 15px;
    }
    table {
      border: 1px solid #ede6d6;
      background: #fff;
      width: 100%;
      tr {
        padding: 10px;
        display: flex;
        flex-direction: column;
        td {
          .year {
            margin-left: 10px;
          }

          ul {
            display: flex;
            flex-wrap: wrap;
            margin-left: 30px;
            li {
              padding: 10px;
              text-decoration: underline;
              color: #4c69bb;
              cursor: pointer;
              font-size: 14px;
            }
            li:hover {
              color: red;
            }
          }
        }
      }
    }
  }
}

/* WebKit 内核浏览器 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background: #9cc3ce;
  border-radius: 10px;
}

/* 其他浏览器 */
* {
  scrollbar-width: auto;
  scrollbar-color: #9cc3ce #f2f2f2;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background: #9cc3ce;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}
</style>